<template>
  <div class="wrapper">
    <div class="left">
      <span>8:00</span>
      <span>12:00</span>
      <span>16:00</span>
      <span>20:00</span>
    </div>
    <div class="right">
      <div class="dates">
        <span
          v-for="(object, objectIndex) in $store.getters.availability.slice(0,7)"
          :key="objectIndex"
        >{{ dateText(object.date) }}</span>
      </div>
      <div class="timetable">
        <div
          v-for="(row, rowIndex) in timetable"
          :key="rowIndex"
          class="row"
        >
          <div
            v-for="(cell, cellIndex) in row"
            :key="cellIndex"
            class="cell"
            :class="{
              available: cell == 0,
              divider: (rowIndex + 2) % 4 == 0,
              divider__dark: rowIndex == 6 || rowIndex == 22 || rowIndex == 38 || rowIndex == 54}"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timetable: [],
    };
  },
  mounted() {
    this.generateTimetable();
  },
  methods: {
    generateTimetable() {
      for (let i = 0; i < 69; i += 1) {
        const row = [];
        for (let j = 0; j < 7; j += 1) {
          const value = this.isUnavailable(j, i) ? 1 : 0;
          row.push(value);
        }
        this.timetable.push(row);
      }
    },

    isUnavailable(column, row) {
      const { ranges } = this.$store.getters.availability[column];
      return ranges.some((range) => row >= range.start.index && row < range.end.index);
    },

    dateText(date) {
      const availabilityDate = new Date(date);
      const day = (availabilityDate.getDate()).toString().padStart(2, '0');
      const month = (availabilityDate.getMonth() + 1).toString().padStart(2, '0');
      return `${day}.${month}`;
    },

  },
};
</script>

  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&display=swap');
  @import 'colors.scss';

  .wrapper{
    width: 100%;
    max-width: 400px;
    background-color: $color4;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    font-family: 'Poppins', sans-serif;
    padding: 30px 0;
    display: flex;
    justify-content: center;
    border-radius: 15px;;
  }

  .left{
    margin-top: 46px;
    width: 32px;
    padding-left: 20px;

    span{
      text-align: left;
      font-size: 10px;
      font-weight: 600;
      margin-bottom: 39px;
      display: block;
      color: $color1;
    }
  }

  .right{
    padding-right: 20px;
    flex-grow: 1;
  }

  .dates{
    color: $color1;
    display: flex;
    width: 100%;
    max-width: 322px;
    margin: 0 auto;

    span{
      display: block;
      width: 100%;
      max-width: 46px;

      &:not(:last-child){
        margin-right: 2px;
      }
    }
  }
  .timetable{
    width: 100%;
    max-width: 322px;
    color: $color1;
    font-size: 18px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
  }

  .cell {
    width: 100%;
    max-width: 46px;
    height: 3px;
    background-color: $color3;

    &:not(:last-child){
      margin-right: 2px;
    }

    &.available{
      background-color: $color5;
    }

    &.divider{
      border-top: 1px solid $color4;

      &__dark{
        border-top: 3px solid $color4;
      }
    }
  }
  </style>
