<template>
  <router-view />
</template>
<script>
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import './components/colors.scss';

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: $color3;
  color: $color2;
  min-height: 80vh;
}

#app{
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

</style>
