<template>
  <div class="popup">
    <p><span>Trenējies lētāk nogalēs un vēlajos vakaros!</span></p>
    <p>
      Zemākas cenas rezervācijām līdz 14:00 un no 21:00 darba dienās
      un visas dienas garumā sestdienās un svētdienās!
    </p>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
@import "colors.scss";

.popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 17px;
    background-color: $color2;
    color: $color3;
    position: absolute;
    top: 0;
    left: 0;
    font-weight: normal;
    p{
        padding: 3px;
        margin: 0;
        span{
            font-weight: 600;
        }

        &:first-child{
            margin-bottom: 3px;
        }
    }
}
</style>
